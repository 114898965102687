@import '../../../css/color.scss';

.bg-secondary {
  background: $backgroundSecondary;
  color: $foregroundDarkest;
}

.bg-gray-light {
  background: $backgroundLighter;
  color: $foregroundDarkest;
}

.bg-info {
  background: $success;
  color: $foregroundLightest;
}

.formlabel-caption{
  .MuiTypography-body1 {
    font-size : 11px
  }
}

.app {
  .bg-gray-booking-light{
    color: $foregroundDarkest;
    background-color: $backgroundLighter;
  }
  .bg-gray-booking-light-action{
    color: $foregroundDarkest;
  }
  .form-booking {
    label {
      padding-top: 15px;
      align-items: start;

      span {
        font-size: 0.8rem;
      }
    }

    button {
      margin: 15px auto;
      width: 50%;
    }
  }

  .imgSDR {
    width: 150px;
    height: 150px;
    float: right;
  }

  table {
    &.tableSummaryBooking {
      tbody {
        tr {
          td {
            border: none;

            &.text-bold {
              font-weight: bold;
            }

            &.text-italic {
              font-style: italic;
            }

            &.text-right {
              text-align: right;
            }
          }

          &.TableFooter {
            td {
              background-color: $backgroundLighter;
            }
          }

          &.border-top {
            td {
              border-top: 1px solid $foregroundDark;
            }
          }

          &.border-bottom {
            td {
              border-bottom: 1px solid $foregroundDark;
            }
          }
        }
      }
    }

    &.tableBooking {
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
        background: $disabledBackground;
      }

      thead {
        th {
          text-align: center;
          background-color: $backgroundLighter;
          border-right: 1px dotted $foregroundDark;
          font-size: 0.8rem;

          &:last-child {
            border-right: none;
          }
        }
      }

      tbody {
        td {
          font-size: 0.8rem;
          border-right: 1px dotted $foregroundDark;
          text-align: center;
          padding: 0;

          &.text-time {
            color: $foregroundDarkSofter;
            text-align: left;
            padding: 10px;
            white-space: nowrap;
          }

          div {
            background-color: $backgroundLightest;
            padding: 10px;
            cursor: pointer;

            svg {
              font-size: 1.7rem;
              color: $foregroundLight;
            }

            &:hover {
              box-shadow: 0 3px 3px -2px $shadowDarker;
              background-color: $backgroundPrimary;

              svg {
                color: $backgroundSecondary;
              }
            }

            &.disable {
              pointer-events: none;
              background-color: $backgroundLighter;

              svg {
                color: rgba($foregroundDark, 0.25);
              }
            }

            &.select {
              background-color: $backgroundPrimary;

              svg {
                color: $backgroundSecondary;
              }
            }
          }

          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }

  .formListBooking {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .card-list {
    &.bookingList {
      border: thin solid $borderLight;
      border-radius: 4px;
      font-size: 0.8rem;

      .card-list-header {
        background-color: $backgroundLight;
        cursor: pointer;
        padding: 0 0 8px 0;
        svg {
          &.arrow {
            float: right;
            font-size: 1rem;
            margin-right: 8px;
            margin-top: 20px;
            -webkit-transition: -webkit-transform .2s ease-in-out;
            -ms-transition: -ms-transform .2s ease-in-out;
            transition: transform .2s ease-in-out;
          }
        }

        &.open {
          svg {
            &.arrow {
              transform: rotate(-180deg);
              -ms-transform: rotate(-180deg);
              --webkit-transform: rotate(-180deg);
              --o-transform: rotate(-180deg);
            }
          }
        }

        .icon {
          svg {
            margin-top: 8px;

            &.statusIcon {
              color: $foregroundPrimary;
            }

            &.confirmedIcon {
              color: $success;
            }

            &.cancelledIcon {
              color: $error;
            }
          }
        }

        &.open {
          border-bottom: thin solid rgba($backgroundLightest, 0.12);
        }

        p {
          font-size: 0.8rem;
        }

        span {
          strong {
            color: $foregroundDark;
          }
        }
      }


      .card-list-body {
        background-color: $backgroundLightest;

        ul {
          li {
            padding-left: 8px !important;
            padding-right: 8px !important;

            span, p {
              font-size: 0.8rem;
            }
          }
        }
      }

      .block-list {
        &.item, &.date, &.schedule {
          padding-top: 8px;
        }

        &.category {
          padding-top: 8px;
          @media(max-width: 600px) {
            span {
              display: none
            }
            p {
              margin-top: -10px;
            }
          }
        }

        &.icon {
          width: 35px;

        }

        &.booking_meeting_room {
          width: 18%;
          @media(max-width: 600px) {
            width: 50%;
          }
        }

        &.booking_price {
          width: 18%;
          @media(max-width: 600px) {
            width: 50%;
          }
        }

        &.status {
          width: 18%;
          @media(max-width: 600px) {
            width: 100%;
          }
        }

        &.action {
          width: 10%;
          @media(max-width: 600px) {
            width: 100%;
          }

          .iconActionBookingDelete {
            margin-top: 5px;
            float: right;
            cursor: pointer;
          }
        }
      }

      &.accounting {
        .card-list-header {
          padding: 8px;
        }

        .block-list {
          &.item {
            width: 18%;
            @media(max-width: 600px) {
              width: 50%;
            }
          }
        }
      }

      .block-availability-content {
        padding: 8px;

        .block-hour {
          text-align: center;
          font-size: 0.65rem;

          &.block-not-availability-hour {
            text-decoration: line-through;
          }
        }

        &.MuiCollapse-hidden {
          padding: 0;
        }
      }
    }
  }

  .avatarSmall {
    width: 24px;
    height: 24px;
    font-size: 0.95rem;
    background-color: $backgroundPrimary;
  }

  .labelFormAvatar {
    .MuiListItemAvatar-root {
      min-width: 30px;
    }
  }

  .block-availability {

    .block-availability-header {
      cursor: pointer;
      padding: 0 0 8px 0;

      svg {
        float: left;
        font-size: 1rem;
        margin-bottom: -3px;
        -webkit-transition: -webkit-transform .2s ease-in-out;
        -ms-transition: -ms-transform .2s ease-in-out;
        transition: transform .2s ease-in-out;
      }

      &.open {
        svg {
          transform: rotate(-180deg);
          -ms-transform: rotate(-180deg);
          --webkit-transform: rotate(-180deg);
          --o-transform: rotate(-180deg);
        }
      }
    }

    .block-availability-content {
      padding: 8px;

      .block-hour {
        text-align: center;
        font-size: 0.65rem;

        &.block-not-availability-hour {
          text-decoration: line-through;
        }
      }

      &.MuiCollapse-hidden {
        padding: 0;
      }
    }
  }

  .bkColImgBk {
    hr {
      background-color: $backgroundLightest;
      margin-bottom: 32px;
      opacity:$foregroundOpacityLine;
    }

    color: $foregroundLightest;
    background: url("../../../images/booking/bkColBooking-vertical.jpg") no-repeat center left;
    background-size: cover;
    @media(max-width: 959px) {
      background: url("../../../images/booking/bkColBooking-horizontal.jpg") no-repeat bottom right;
      background-size: cover;
    }
    @media(min-width: 960px) {
      min-height: 497px;
    }
    @media(max-width: 600px) {
      background: none;
      height: auto;
      .button {
        position: absolute;
        right: 24px;
        top: 8px;

        &.bg-white {
          background: $backgroundPrimary;
          border: thin solid $borderPrimary;
          color: $foregroundLightest;

          &.center {
            position: static;
            display: block;
            width: 160px;
            margin: 0 auto;
            text-align: center;
          }
        }
      }
      h2 {
        color: $foregroundPrimary;
      }
      hr {
        background-color: $backgroundPrimary;
        margin-bottom: 0;
      }
      .small-y {
        padding: 16px 16px 0 16px;
      }
    }
    position: relative;
  }

  .serviceType {
    background-color: $foregroundLightest;
    color: $foregroundPrimary;
    border: 1px solid $borderPrimary;
    .meeting {
      background-image: url("../../../images/booking/icon-1.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    .openDesktop {
      background-image: url("../../../images/booking/icon-2.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    &.select {
      background-color: $backgroundPrimary;
      font-weight: bold;
      color: $foregroundLightest;
      border: 1px solid $borderPrimary;
      .meeting {
        background-image: url("../../../images/booking/icon-1-hover.png");
      }
      .openDesktop {
        background-image: url("../../../images/booking/icon-2-hover.png");
      }
    }
    &:hover { // changes colors for hover state
      background-color: $backgroundPrimary;
      font-weight: bold;
      color: $foregroundLightest;
      border: 1px solid $borderPrimary;
      .meeting {
        background-image: url("../../../images/booking/icon-1-hover.png");
      }
      .openDesktop {
        background-image: url("../../../images/booking/icon-2-hover.png");
      }
    }
  }

  &.dark {
    .avatarSmall {
      background-color: $info;
    }
    .bg-gray-booking-light{
      color: $foregroundLightest;
      background-color: $foregroundDark;
    }
    .bg-gray-booking-light-action{
      color: $foregroundLightest;
    }
    .bg-secondary-action{
      color: $foregroundDarkest;
    }
    .card-list {
      &.bookingList {
        border: thin solid $foregroundDark;

        .card-list-header {
          background-color: rgba($backgroundLight, 0.12);

          .icon {
            svg {
              &.statusIcon {
                color: $info;
              }
            }
          }
        }

        .card-list-body {
          background-color: rgba($backgroundLight, 0.12);
        }
      }

      &.accounting {
        .card-list-header {
          background-color: rgba($backgroundLight, 0.32);
        }
      }

      .block-list {
        span {
          color: $foregroundDarkSofter;

          strong {
            color: $foregroundLightest;
          }
        }
      }
    }

    .block-message {
      .block-message-header {
        color: $foregroundDarkSofter;
      }
    }
  }
}

.text-small {
  font-size: 0.8rem;
}

